import { Link } from "gatsby"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H1 } from "../../styles/TextStyles"

interface ContactSectionProps {
  title?: string
}

const ContactSection: FunctionComponent<ContactSectionProps> = props => {
  const { title = " Contact Us" } = props
  return (
    <Wrapper>
      <TextWrapper>
        <Title>{title}</Title>
      </TextWrapper>
      <ContactWrapper>
        <Icon src="/assets/icons/email.png" />
        <a href="mailto:info@joshuaorganisation.com">
          Info@joshuaorganisation.com
        </a>
        <Icon src="/assets/icons/phone.png" />
        <a href="tel: 0210133024">0210133024</a>
        <Icon src="/assets/icons/whatsapp.png" />
        <a href="tel: 065 336 3518">065 336 3518</a>
      </ContactWrapper>
      <LinkWrapper>
        <Link to="https://www.facebook.com/Joshuas-Organisation-2374424205925522/">
          <Image src="/assets/icons/facebook.png" />
        </Link>
        <Link to="https://www.instagram.com/joshuasorganisation/?utm_medium=copy_link">
          <Image src="/assets/icons/instagram.png" />
        </Link>
        <Link to="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fm%2Fcompany%2Fjoshua-organisation">
          <Image src="/assets/icons/linkedin.png" />
        </Link>
      </LinkWrapper>
    </Wrapper>
  )
}

export default ContactSection

const Wrapper = styled.div`
  padding: 100px 80px;
  display: grid;
  gap: 30px;
  @media (max-width: 640px) {
    padding: 200px 38px;
    gap: 50px;
  }
`
const TextWrapper = styled.div`
  display: grid;
`
const Title = styled(H1)`
  font-weight: bold;
  @media (max-width: 640px) {
    font-size: 40px;
  }
`
const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  gap: 20px;
  align-items: center;

  a {
    text-decoration: none;
    color: black;
    font-size: 22px;
    @media (max-width: 640px) {
      font-size: 17px;
    }
  }

  @media (max-width: 640px) {
    grid-template-columns: 50px auto;
  }
`
const LinkWrapper = styled.div`
  display: grid;
  grid-template-columns: 80px 80px 80px;
  justify-content: center;
  gap: 20px;
  @media (max-width: 640px) {
    gap: 20px;
  }
`
const Icon = styled.img`
  width: 30px;

  @media (max-width: 640px) {
    width: 30px;
  }
`
const Image = styled.img`
  width: 50px;
  @media (max-width: 640px) {
    width: 60px;
  }
`
