import React, { FunctionComponent } from "react"
import styled from "styled-components"
import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"
import Line from "../components/lines/Line"
import ContactSection from "../components/sections/ContactSection"

interface ContactProps {
  title?: string
  name?: string
  content?: string
}

const Contact: FunctionComponent<ContactProps> = props => {
  const {
    title = "Contact Us",
    name = "Keywords",
    content = "Education",
  } = props
  return (
    <Layout>
      <SEO title={title} />
      <meta name={name} content={content} />
      <LineWrapper>
        <Line />
      </LineWrapper>
      <ContactSection />
    </Layout>
  )
}

export default Contact
const LineWrapper = styled.div`
  margin: 120px 0 0 0;
  display: grid;
  justify-content: center;
`
